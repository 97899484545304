import { useApiGet, useApiPost } from '@papertrail/web3-utils'

export function useApiGetContacts(accountId) {
  return useApiGet(`/accounts/${accountId}/contacts`, mapContacts)
}

export function useApiGetContactTypes(accountId) {
  return useApiGet(`/accounts/${accountId}/lookups/contact_type`, (data) => data.data)
}

export function useApiShareCert(accountId: string, certificateId: string) {
  const [shareState, post, reset] = useApiPost(
    `/accounts/${accountId}/certificates/${certificateId}/actions/share/certificate.pdf`,
    (data) => data
  )

  function share(data) {
    const payload = {
      recipients: data.emails
    }

    post(payload)
  }

  return [shareState, share, reset] as const
}

export function useApiShareReport(accountId: string, reportId: string) {
  const [shareState, post, reset] = useApiPost(
    `/accounts/${accountId}/exports/${reportId}/actions/share/report.pdf`,
    (data) => data
  )

  function share(data) {
    console.log('data is', { data, accountId, reportId })

    const payload = {
      recipients: data.emails
    }

    post(payload)
  }

  return [shareState, share, reset] as const
}

function mapContacts(data) {
  return data.data.map((c) => ({
    id: c.id,
    email: c.email,
    name: c.name,
    typeId: c.type_id,
    added: false
  }))
}
