import React, { useState, useEffect, useCallback } from 'react'
import { useApiGetContacts, useApiGetContactTypes, useApiShareCert, useApiShareReport } from '../hooks'
import { useSessionAccount } from '@papertrail/web3-session'

import {
  Select,
  MenuItem,
  OutlinedInput,
  Bold,
  NoData,
  OwnersIcon,
  ActionSuccess,
  Label,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  ButtonPrimary,
  ButtonSecondary,
  ColorAvatar
} from '@papertrail/styleguide'

export default function ShareReportOrCert() {
  const [open, setOpen] = useState(false)
  const [docUrl, setDocUrl] = useState<string | undefined>()
  const [mode, setMode] = useState<string | undefined>()

  const account = useSessionAccount()
  const accountId = account ? account.id : null

  useEffect(() => {
    addEventListener('shareDocument', (e) => {
      const docUrl = (e as any).detail.docUrl
      const mode = (e as any).detail.mode

      setDocUrl(docUrl)
      setOpen(true)
      setMode(mode)
      resetComponentState()
    })
  }, [])

  const urlBits = docUrl ? docUrl.split('/') : ''
  const docId = urlBits ? urlBits[3] : ''

  const [contactsState, getContacts] = useApiGetContacts(accountId)
  const [contactsTypesState, getContactTypes] = useApiGetContactTypes(accountId)
  const [shareCertState, postCertShare, resetCertShareState] = useApiShareCert(accountId, docId)
  const [shareReportState, postReportShare, resetReportState] = useApiShareReport(accountId, docId)

  const [currentType, setCurrentType] = useState('null')
  const [modalOpen, setModalOpen] = useState(false)
  const [searchResult, setSearchResult] = useState([])
  const [emails, setEmails] = useState('')
  const [formValidationError, setFormValidationError] = useState<any>()

  function postShare(emails) {
    if (mode === 'report') {
      postReportShare({ emails })
    } else {
      postCertShare({ emails })
    }
  }

  // the modal can be used to share a certificate or a report
  // the flow is exactly the same but it calls different endpoints and uses different state

  useEffect(() => {
    resetComponentState()
    if (accountId) {
      getContacts({})
      getContactTypes({})
    }
  }, [accountId, docId])

  function resetComponentState() {
    setCurrentType('null')
    setEmails('')
    setSearchResult([])
    setFormValidationError(null)

    resetCertShareState()
    resetReportState()
  }

  function onAddOrRemove(contact) {
    const newContacts = searchResult.map((c) => {
      if (c.email === contact.email) {
        return {
          ...c,
          added: !c.added
        }
      } else {
        return c
      }
    })

    setSearchResult(newContacts)
  }

  function getEmails() {
    const selectedEmails = searchResult.filter((c) => c.added).map((c) => c.email)
    const customEmails = emails.trim() ? emails.split(',') : []
    return [...selectedEmails, ...customEmails]
  }

  function search(text: string) {
    let newContacts
    const existingSelectedContacts = searchResult.filter((r) => r.added)

    if (text.length > 0) {
      // mui doesn't accept null or undefined, it needs a string
      if (currentType === 'null') {
        newContacts = contactsState.data.filter((c) => {
          if (
            (c.email && c.email.length && c.email.toLowerCase().indexOf(text.toLowerCase()) >= 0) ||
            c.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
          ) {
            return true
          } else {
            return false
          }
        })
      } else {
        newContacts = contactsState.data.filter((c) => {
          if ((c.email.indexOf(text) >= 0 || c.name.indexOf(text) >= 0) && c.typeId === currentType) {
            return true
          } else {
            return false
          }
        })
      }
    } else {
      // if the user removes the search string we remove all results and we check against existing ones
      newContacts = []
    }

    const extraContacts = []

    for (const contact of existingSelectedContacts) {
      const existingContact = newContacts.find((nc) => nc.id === contact.id)

      if (existingContact) {
        existingContact.added = true
      } else {
        // we can't modify the collection during iteration
        extraContacts.push(contact)
      }
    }

    newContacts = [...newContacts, ...extraContacts].sort((c1, c2) => (c1.name >= c2.name ? 1 : -1))
    setSearchResult(newContacts)
  }

  function onCancel() {
    setOpen(false)
  }

  function emailError(emails) {
    if (emails.trim() !== '') {
      const emailList = emails.split(',')
      const regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      for (const email of emailList) {
        if (!email.trim().match(regex) && email.trim() !== '') {
          return true
        }
      }
      return false
    } else {
      return false
    }
  }

  function getSelected() {
    return (
      <Box sx={{ display: 'flex', marginTop: '5px' }}>
        {searchResult
          .filter((r) => r.added)
          .map((r) => (
            <Box sx={{ marginLeft: '-7px' }} key={`selected-${r.id}`}>
              <ColorAvatar name={r.name} />
            </Box>
          ))}
      </Box>
    )
  }

  const searchSelection = searchResult.filter((c) => c.added)

  function onShareClick() {
    const err = getFormValidationError()
    const emails = getEmails()

    if (!err) {
      postShare(emails)
    } else {
      triggerFormValidation()
    }
  }

  function getFormValidationError() {
    if (emailError(emails)) {
      return 'Some emails are invalid.'
    } else if (emails.trim() === '' && searchSelection.length === 0) {
      return 'Please add at least one recepient.'
    } else {
      return null
    }
  }

  function triggerFormValidation() {
    setFormValidationError(getFormValidationError())
  }

  const stateIsLoaded = shareCertState.isLoaded || shareReportState.isLoaded
  const stateIsLoading = shareCertState.isLoading || shareReportState.isLoading

  const handleEscapeKeyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      onCancel()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKeyPress)

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [])

  if (open) {
    return (
      <Dialog open={true} maxWidth={false} onClick={() => setModalOpen(false)}>
        <DialogTitle>Share {mode}</DialogTitle>
        {!stateIsLoaded && (
          <DialogContent sx={{ width: '800px' }}>
            <Box>
              {formValidationError && (
                <Box sx={{ marginBottom: 1.5 }}>
                  <Label variant="error" text={formValidationError} />
                </Box>
              )}

              <Box>Share via email or choose from account contacts.</Box>

              <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                <Bold>Contact type</Bold>
                <Select
                  sx={{ width: '100%' }}
                  label=""
                  value={currentType}
                  onChange={(e) => setCurrentType(e.target.value)}>
                  <MenuItem disabled value="null">
                    <span style={{ color: '#ccc' }}>Select type</span>
                  </MenuItem>

                  {contactsTypesState.isLoaded &&
                    contactsTypesState.data.map((ct) => (
                      <MenuItem key={ct.id} value={ct.id}>
                        {ct.value}
                      </MenuItem>
                    ))}
                </Select>
              </Box>

              <Bold>Choose from account contacts</Bold>

              <Box sx={{ position: 'relative' }}>
                <OutlinedInput
                  sx={{ width: '100%' }}
                  label=""
                  placeholder="Search contact"
                  onChange={(e) => search(e.target.value)}
                  onFocus={() => setModalOpen(true)}
                  onClick={(e) => e.stopPropagation()}
                />

                {modalOpen && (
                  <Box
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                      position: 'fixed',
                      backgroundColor: 'white',
                      width: '750px',
                      zIndex: 10000,
                      padding: '15px',
                      borderRadius: '3px',
                      boxShadow: '0 3px 5px rgba(0, 0, 0, 0.3)'
                    }}>
                    {contactsState.isLoaded && searchResult.length === 0 && (
                      <NoData icon={OwnersIcon} text="There are no search results." />
                    )}

                    {contactsState.isLoaded &&
                      searchResult.map((c) => (
                        <Box
                          key={`result-${c.id}`}
                          sx={{
                            display: 'flex',
                            alignItems: 'space-between',
                            justifyContent: 'space-between',
                            margin: 'auto',
                            marginBottom: '12px'
                          }}>
                          <Box
                            sx={{
                              marginBottom: '1px',
                              display: 'flex',
                              flexDirection: 'row',
                              gap: 1,
                              alignItems: 'center'
                            }}>
                            <ColorAvatar name={c.name} />
                            <Box>
                              <Box>{c.name}</Box>
                              <Box sx={{ fontSize: '13px' }}>{c.email}</Box>
                            </Box>
                          </Box>

                          <input type="checkbox" checked={c.added} onChange={() => onAddOrRemove(c)} />
                        </Box>
                      ))}
                  </Box>
                )}
              </Box>

              {getSelected()}

              <br />
              <Bold>Additional Emails</Bold>
              <OutlinedInput
                multiline={true}
                sx={{ width: '100%' }}
                label=""
                placeholder="Enter comma separated emails"
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
              />
            </Box>
          </DialogContent>
        )}

        {stateIsLoaded && (
          <DialogContent style={{ width: '600px', height: '150px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <ActionSuccess title="Share successful." message={`Your have successfully shared your ${mode}.`} />
            </Box>
          </DialogContent>
        )}

        <DialogActions>
          {!stateIsLoaded && (
            <>
              <ButtonSecondary onClick={onCancel}>Close</ButtonSecondary>
              <ButtonPrimary disabled={stateIsLoading} onClick={onShareClick}>
                {stateIsLoading ? 'Sharing...' : 'Share'}
              </ButtonPrimary>
            </>
          )}

          {stateIsLoaded && <ButtonPrimary onClick={onCancel}>Close</ButtonPrimary>}
        </DialogActions>
      </Dialog>
    )
  } else {
    return null
  }
}
